/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { get } from 'lodash-es';
import config from '../utils/siteConfig';

function SEO({
  description, lang, meta, title, location,
}) {
  const query = graphql`
      query SiteQuery{
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `;

  return (
    <StaticQuery
      query={query}
      render={({ site }) => {
        const metaDescription = description || get(site, 'siteMetadata.description', '');
        const metaTitle = get(site, 'siteMetadata.title', '');
        const metaAuthor = get(site, 'siteMetadata.author', '');
        const canonical = `${config.siteUrl}${get(location, 'pathname')}`;

        return (
          <>
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              titleTemplate={`%s | ${metaTitle}`}
              url={canonical}
              meta={[
                {
                  name: 'description',
                  content: metaDescription,
                },
                {
                  property: 'og:title',
                  content: title,
                },
                {
                  property: 'og:description',
                  content: metaDescription,
                },
                {
                  property: 'og:type',
                  content: 'website',
                },
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
                {
                  name: 'twitter:creator',
                  content: metaAuthor,
                },
                {
                  name: 'twitter:title',
                  content: title,
                },
                {
                  name: 'twitter:description',
                  content: metaDescription,
                },
              ].concat(meta)}
            />
          </>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  location: {},
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default SEO;
