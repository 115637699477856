import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import './ContactForm.scss';
import emailjs from 'emailjs-com';
import { Oval as Loader } from 'react-loader-spinner';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import cn from 'classnames';

const ContactForm = (props) => {
  ContactForm.propTypes = {
    hideTitle: PropTypes.bool,
    className: PropTypes.string,
    btnTitle: PropTypes.string,
  };
  ContactForm.defaultProps = {
    hideTitle: false,
    className: '',
    btnTitle: 'Contact Us',
  };
  const [emailContactStatus, setEmailContactStatus] = useState('');
  const [formNameValid, setFormNameValid] = useState(true);
  const [formEmailValid, setFormEmailValid] = useState(true);

  const clearForm = (form) => {
    form.reset();
  };
  const sendContactEmail = (target) => {
    setEmailContactStatus('SENDING');
    emailjs.sendForm('build-is', 'template_T8MNQPO0', target, 'user_Rw2GD8OULuKAbasyV0kIP')
      .then((result) => {
        console.log(result);
        setEmailContactStatus('SUCCESS');
        clearForm(target);
      }, (error) => {
        console.log(error.text);
        setEmailContactStatus('ERROR');
      });
  };

  const validateAndSend = (e) => {
    e.preventDefault();
    const fromNameValue = e.target.from_name.value;
    const fromEmailValue = e.target.from_email.value;
    let isNameValid = false;
    let isEmailValid = false;
    if (fromNameValue && fromNameValue !== '') {
      isNameValid = true;
    }
    if (fromEmailValue && fromEmailValue !== '') {
      isEmailValid = true;
    }
    setFormNameValid(isNameValid);
    setFormEmailValid(isEmailValid);
    if (isNameValid && isEmailValid) {
      sendContactEmail(e.target);
    } else {
      setEmailContactStatus('INVALID');
    }
  };

  const handleNameInput = (e) => {
    if (e.target.value !== '') {
      setFormNameValid(true);
    }
  };

  const handleEmailInput = (e) => {
    if (e.target.value !== '') {
      setFormEmailValid(true);
    }
  };

  const { hideTitle, className, btnTitle } = props;
  return (
    <Container className={cn(className, 'contact-us-form-container p-0')} fluid>
      <div className="form-container">
        <div className="form-wrapper position-relative d-flex flex-column">
          {hideTitle ? null
            : (
              <div className="title-text d-flex flex-column">
                <p className="build-title fw-bold mb-0">Let&apos;s Build together!</p>
                <p className="title-question">Do you have any project we can assist you with?</p>
              </div>
            )}
          <form name="sendEmailForm" className="contact-form d-flex flex-column" onSubmit={validateAndSend}>
            <label className={!formNameValid ? 'invalid' : ''}>Name *</label>
            <input type="text" name="from_name" onChange={(event) => handleNameInput(event)} />
            <label className={!formEmailValid ? 'invalid' : ''}>Email Address *</label>
            <input type="email" name="from_email" onChange={(event) => handleEmailInput(event)} />
            <label>I am interested in...</label>
            <input type="text" name="from_interest" />
            <label>Message</label>
            <textarea rows="6" name="message_html" />
            <div className="send-button-container position-relative d-flex align-items-center justify-content-center">
              <input className="send-button fw-bold" type="submit" value={btnTitle} />
              {emailContactStatus === 'SENDING' ? (
                <Loader
                  className="loader"
                  type="Puff"
                  color="#04BCCA"
                  height="35px"
                  width="40px"
                />
              ) : null}
              {emailContactStatus === 'SUCCESS'
                ? <FaCheckCircle className="icon success" /> : null}
              {emailContactStatus === 'ERROR' || emailContactStatus === 'INVALID'
                ? <FaExclamationCircle className="icon invalid" /> : null}
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default ContactForm;
