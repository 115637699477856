module.exports = {
  siteUrl: 'https://www.build.global/',
  title: 'Build to',
  description: 'Build with build',
  author: 'Build',
  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image
  siteIcon: 'favicon-build.png', // Logo in /static dir used for SEO, RSS, and App manifest
  themeColor: '#15171A',
};
