import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';
import { Oval as Loader } from 'react-loader-spinner';

const CustomLoader = ({
  className,
  type,
  color,
  height,
  width,
  visible,
  position,
}) => (
  <Loader
    className={`${className} ${position}-aligned`}
    type={type}
    color={color}
    height={height}
    width={width}
    visible={visible}
  />
);
CustomLoader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  visible: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'center', 'right']),
};
CustomLoader.defaultProps = {
  className: '',
  type: 'Puff',
  color: '#04BCCA',
  height: '35px',
  width: '40px',
  visible: true,
  position: 'center',
};
export default CustomLoader;
