import React from 'react';
import Container from 'react-bootstrap/Container';
import './GetInTouch.scss';
import dottedline from '../../../images/dotted-line.svg';

const GetInTouch = () => (
  <Container className="contact-home-container" fluid>
    <div className="left-side">
      <h2 className="heading"> Get in Touch </h2>
      <p className="build-title">
        Let&apos;s Build your next project together.
      </p>
      <p className="question-title">Ready to start?</p>
    </div>
    <div className="right-side">
      <img className="dotted-line" src={dottedline} alt="DottedLine" />
      <a href="/contact-us/#contact-form"> Contact Us </a>
    </div>
  </Container>
);
export default GetInTouch;
